import { useStaticQuery, graphql } from 'gatsby';

export const usePageTelephoniadataCs = () => {
  const data = useStaticQuery(graphql`
    query PageTelephonia {
      wpPage(id: { eq: "cG9zdDoxODM=" }) {
        id
        priceListUrlID {
          url
        }
        gallery {
          images {
            altText
            sourceUrl
            title
            description
          }
        }
        productFeatures {
          features {
            description
            header
          }
        }
        produkt {
          hlavniPopis
          nazev
          popis
          productLink
          barva
          copy
          fieldGroupName
          image {
            sourceUrl
          }
          logo {
            sourceUrl
          }
        }
        technicalFeatures {
          list {
            header
          }
        }
        featuresIcons {
          feature {
            name
            features {
              li
            }
            icon {
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  return data.wpPage;
};
