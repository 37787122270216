import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { usePageTelephoniadataCs } from '../hooks/cs/page-telephonia';
import { usePageTelephoniadataEn } from '../hooks/en/page-telephonia.en';

export function PageTelephoniaMetadata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = usePageTelephoniadataCs();
  } else {
    data = usePageTelephoniadataEn();
  }
  return data;
}
