// extracted by mini-css-extract-plugin
export var blue = "product-module--blue--u8pd7";
export var copy = "product-module--copy--2Vked";
export var featureList = "product-module--featureList--JIBRf";
export var featureListv2 = "product-module--featureListv2--0GcqF";
export var green = "product-module--green--gSk4k";
export var iconProductFeaturesList = "product-module--iconProductFeaturesList--7buA3";
export var image = "product-module--image--mq5u0";
export var imageGallery = "product-module--imageGallery---ByzH";
export var orange = "product-module--orange--XUhvT";
export var priceTable = "product-module--priceTable--XiGdc";
export var purple = "product-module--purple--TZx70";