import React, { useRef, useState } from 'react';
import * as style from '../styles/gallery.module.scss';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Lazy, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/lazy';
export default function Gallery({ images }: { images: any[] }) {
  const swiper = useRef(null);
  const [initialSlide] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);

  const showFullscreen = (key: number) => {
    (swiper.current as any)?.swiper?.slideTo(key, 0);
    setTimeout(() => {
      setFullscreen(true);
    }, 50);
  };

  const hideFullscreen = () => {
    setFullscreen(false);
  };

  const detectClickOut = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if ((event.target as any).contains(swiper.current)) {
      hideFullscreen();
    }
  };

  return (
    <div>
      <ul className={style.images}>
        {images?.map((img: any, i: number) => (
          <li key={i}>
            <img
              onClick={(event) => showFullscreen(event, i)}
              src={img.sourceUrl}
              alt={img.altText}
            />
          </li>
        ))}
      </ul>

      <div
        className={style.imagesFullscreen}
        onClick={(event) => detectClickOut(event)}
        style={{ display: fullscreen ? 'block' : 'none' }}
      >
        <div className={style.closeButton} onClick={hideFullscreen}>
          <img src="/assets/icons/cross.svg" alt="Close" />
        </div>
        <Swiper
          style={{
            '--swiper-navigation-color': '#944651',
            '--swiper-pagination-color': '#944651',
          }}
          lazy={true}
          slidesPerView={'auto'}
          navigation={true}
          modules={[Lazy, Navigation]}
          spaceBetween={70}
          autoHeight={true}
          initialSlide={initialSlide}
          centeredSlides={true}
          className={style.swiper}
          ref={swiper}
        >
          {images?.map((img: any, i: number) => (
            <SwiperSlide key={i}>
              <img
                onClick={(event) => showFullscreen(event, i)}
                src={img.sourceUrl}
                alt={img.altText}
                loading="lazy"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
