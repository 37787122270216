import * as style from '../styles/iconFeatureListOfProduct.module.scss';
import React from 'react';

export interface featuresIcon {
  id: string;
  name: string;
  features: {
    li: string;
    icon: {
      mediaItemUrl: string;
    };
  }[];
}

export default function IconFeatureListOfProduct(props: { featuresIcons: featuresIcon[] }) {
  if (!props.featuresIcons) return null;

  const featureList = props.featuresIcons.map((icon, index) => ({
    ...icon,
    id: `ui${index}`, // Use a unique identifier based on the index
  }));

  function focusOn(id: any, event: any) {
    console.log(id, event);
    event.preventDefault();

    // eslint-disable-next-line no-undef
    const element = document.querySelector(`[data-hash="${id}"]`);
    console.log(element);
    // eslint-disable-next-line no-undef
    const scroller = document.getElementById('scrollerIcons');

    if (!element || !scroller) {
      return;
    }

    const rect = element.getBoundingClientRect();
    const top = rect.top;
    console.log(top);

    scroller.scrollTo({ top: top, behavior: 'smooth' });
  }

  const Icon = (icon: any) => {
    console.log(icon);
    return (
      <div
        className={style.icon}
        data-hash={'i_' + icon.data.id}
        onClick={(e) => focusOn(icon.data.id, e)}
      >
        <img src={icon?.data?.icon?.mediaItemUrl} alt="" />
        <span dangerouslySetInnerHTML={{ __html: `${icon.data.name}` }}></span>
      </div>
    );
  };

  const Icons = () => {
    const icons: any = [];
    featureList.map((value: any, key: number) => {
      icons.push(<Icon key={key} data={value}></Icon>);
    });
    return <>{icons}</>;
  };

  const Feauture = (data: any) => {
    const fe = data.data;
    const features: any = [];
    fe.features?.map((text: any, key: any) => {
      if (text.li) {
        features.push(<li key={key} dangerouslySetInnerHTML={{ __html: `&gt; ${text.li}` }}></li>);
      }
    });

    return (
      <>
        <h1 data-hash={fe.id}>{fe.name.replace('<br>', '')}</h1>
        <ul>{features}</ul>
      </>
    );
  };

  const AllFeatures = () => {
    const feature: any = [];
    featureList.map((value: any, key: number) => {
      feature.push(<Feauture key={key} data={value}></Feauture>);
    });
    return <>{feature}</>;
  };

  return (
    <div className={style.featureList}>
      <div className={style.icons}>
        <Icons></Icons>
      </div>
      <div className={style.text}>
        <div className={style.scroller} id="scrollerIcons">
          <AllFeatures></AllFeatures>
        </div>
      </div>
    </div>
  );
}
